<template>
<v-app id="bar-app">
    <v-row class="mt-1">
        <div class="col-auto mr-auto ml-5 pb-0 pr-0 logo-title">
            <a href="https://bg.ambafrance.org/">
                <v-img max-height="190" class="logo-el ml-5 pb-3 " max-width="150" src="../../assets/logos/fr_amb.jpg"></v-img>
            </a>
            <a href="http://institutfrancais.bg/">
                <v-img max-height="190" class="logo-el mb-5 mr-5" max-width="150" src="../../assets/logos/fr_inst.png"></v-img>
            </a>
            <br>
            <div class="link-to flex text-left fs-2 my-2">
                <v-icon color="#008fca">mdi-chevron-left </v-icon>
                <h4 class="flex"><a href="http://institutfrancais.bg/">{{$t('Navigation.link')}}</a></h4>
            </div>
            <h1 class="flex text-left fs-2" id="title-nav">{{$t('Navigation.title')}}</h1>
        </div>
        <div class="col-auto lang-flags-container mr-4 pr-0">
            <div class="col flex text-right">
                <v-btn :color="colorBG" depressed @click="changeToBG(),logChangeOfCategory()">
                    <v-img id="bg" max-height="25" max-width="25" src="../../assets/lang_icons/bg.png"></v-img><b>BG</b>
                </v-btn>
                <v-btn :color="colorFR" depressed @click="changeToFR(),logChangeOfCategory()">
                    <v-img id="fr" max-height="25" max-width="25" src="../../assets/lang_icons/fr.png"></v-img><b>FR</b>
                </v-btn>
                <!-- <div class="col mt-5 pr-0"><Form/></div>-->
            </div>
        </div>
    </v-row>
    <category-tabs />
    <category-tabs-mobile />
</v-app>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
    db
} from '../../firebase';
import Form from "../Form.vue"
import CategoryTabs from "../layout/CategoryTabs.vue"
import CategoryTabsMobile from "../layout/CategoryTabsMobile.vue"
import {
    mapState,
    mapGetters,
    mapActions
} from "vuex";
export default {
    components: {
        //Form,
        CategoryTabs,
        CategoryTabsMobile
    },
    name: "NavBar",
    data() {
        return {
            res: null,
            currentID: "id_0"
        }
    },
    computed: {
        ...mapState("common", ["colorBG", "colorFR"]),
    },
    methods: {
        ...mapActions("words", ["search"]),
        ...mapActions("words", ["getItems", "returnTranslation", "getCategoryId"]),
        ...mapActions("common", ["changeToBG", "changeToFR", "logChangeOfCategory"]),

    },
    created() {
        this.getItems()
    }
};
</script>

<style scoped src="@/assets/styles/nav-bar.css">
</style>
