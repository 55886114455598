/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n';

Vue.use(Vuex);

const state = () =>({
    colorBG:"transparent",
    colorFR:"#008fca",
    currentWord: null,
    currentWordTranslated: null,
    isClicked: false,
    currentWordModel:"null",
})

const mutations = {
    CHANGE_LANG_BG(state){
       i18n.locale = 'bg'
    },
    CHANGE_LANG_FR(state){
        i18n.locale = 'fr'
     },
     SET_CURRENT(state,{word,trans})
     {
         state.currentWord = word
         state.currentWordTranslated = trans
     },
     SET_MODEL(state,payLoad){
         state.currentWordModel = payLoad
    }

}

const getters={
    getCurrentWord: state=>state.currentWord,
    getCurrentWordTranslated:state => state.currentWordTranslated,
    getVisibility: state=>state.isClicked,
    getRule:state=>state.currentWordModel
}

const actions={
    changeToBG({state,commit})
    {
        state.colorBG = "#008fca"
        state.colorFR = "transparent"
        commit("SET_CURRENT",{word:null, trans:null})
        commit("CHANGE_LANG_BG")
    },

    changeToFR({state,commit})
    {
        state.colorFR = "#008fca"
        state.colorBG = "transparent"
        commit("SET_CURRENT",{word:null, trans:null})
        commit("CHANGE_LANG_FR")
    },
    
    getTranslationFromDB(state,{item})
    {
        return (i18n.locale == 'bg') ?   item.currentTranslation = item.inBulgarian : item.currentTranslation = item.inFrench
    },

    getCurrentWord({state,commit},{word})
    {
        if(i18n.locale == 'bg'){
           commit("SET_CURRENT",{word:word.inBulgarian, trans:word.inFrench})
        }
        else{
            commit("SET_CURRENT",{word:word.inFrench, trans:word.inBulgarian})
        }
    },
    logChangeOfCategory({state}){
        state.isClicked = false
        state.currentWordModel = null
    },
    logChangeOfWord({state})
    {
        state.isClicked = true
    },
    setNewModel({commit},payLoad)
    {
        commit('SET_MODEL',payLoad)
    }


}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };