/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from 'vuex'
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import {store} from "./store/index";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify';
import VueFirestore from 'vue-firestore'
import "../src/assets/styles/global.css"
require('firebase/firestore')


Vue.use(Vuex)
Vue.use(VueFirestore)
Vue.use(Vuetify);

Vue.config.performance = true
Vue.config.productionTip = false;
new Vue({
  router,
  store : store,
  i18n,
  vuetify : new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");
