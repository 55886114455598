<template>
<v-app class="app-class">
    <v-row>
        <nav-bar />
    </v-row>
    <v-row v-if="isBig" class="main-container">
        <div class="col-5 col-sm-3 col-md-2 pr-0 mt-5 ">
            <search-drawer />
        </div>
        <div class="col px-0" id="def-col">
            <definition-container />
        </div>
    </v-row>
    <v-row v-if="isSmall" class="main-container mt-6">
        <div class="ml-auto mr-auto">
            <mobile-def-and-search-container />
        </div>
    </v-row>
</v-app>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import NavBar from './components/layout/NavBar.vue';
import SearchDrawer from './components/layout/SerachDrawer.vue';
import DefinitionContainer from './components/DefinitionContainer.vue'
import MobileDefAndSearchContainer from './components/MobileDefAndSearchContainer.vue'
export default {
    components: {
        NavBar,
        SearchDrawer,
        DefinitionContainer,
        MobileDefAndSearchContainer
    },
    name: "App",
    data() {
        return {
            isBig: false,
            isSmall: false,
            windowWidth: window.innerWidth
        }
    },
    watch: {
        windowWidth(newHeight) {
            if (newHeight <= 700) {
                this.isSmall = true;
                this.isBig = false;
            } else {
                this.isBig = true;
                this.isSmall = false;
            }
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        }
    },
    created() {
        console.log(this.windowWidth)
        if (this.windowWidth <= 700) this.isSmall = true
        else this.isBig = true
    }
};
</script>

<style>
.app-class {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.main-container {
    background-color: #e4eaf0;
}

html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#def-col {
    width: 100%;
    height: 90vh;
    overflow: hidden;
}
</style>
