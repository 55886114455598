<template>
<v-app>
    <div class="flex text-right">
        <v-dialog v-model="dialog" width="350">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="#e4eaf0" v-bind="attrs" depressed v-on="on">
                    <span class="fw-light fs-6 text-lowercase">Suggest new word</span>
                </v-btn>
            </template>

            <v-card color="#e4eaf0">
                <v-card-title>Do you have a suggestion you'd like to share?</v-card-title>
                <v-container>
                    <div class="text-center">
                        <v-form ref="form" v-model="valid" lazy-validation>

                            <div class="container">
                                <div class="col-md-3">
                                    <input class="form-control" @change="fileAdded" type="file" id="input" accept=".xls,.xlsx">
                                </div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary" id="button" @click.prevent="fileConvert">Convert</button>
                                </div>
                            </div>

                            <!--<v-textarea clearable clear-icon="mdi-close-circle" label="Your suggestion here..." solo></v-textarea>-->
                            <v-text-field v-model="inFrench" label="French"></v-text-field>
                            <v-text-field v-model="inBulgarian" label="Bulgarian"></v-text-field>
                            <v-text-field v-model="category" label="Category"></v-text-field>

                            <v-btn color="blue" dark class="mr-4" @click="test()">
                                Send it!
                            </v-btn>
                        </v-form>
                    </div>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

</v-app>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import {
  mapActions,
    mapGetters,
    mapState
} from 'vuex';

export default {
    name: "Form",
    data() {
        return {
            dialog: false,
            valid: true,
            inBulgarian: null,
            inFrench: null,
            category: null,
            selectedFile: null
        }
    },
    methods: {
        ...mapActions("words",["setEntries"]),
        test() {
            console.log("test", this.inBulgarian)
        },
        fileAdded(event) {
            this.selectedFile = event.target.files[0];
        },
        fileConvert() {
            if (this.selectedFile) {
                let fileReader = new FileReader();
                fileReader.readAsBinaryString(this.selectedFile);
                fileReader.onload = (event) => {
                    let data = event.target.result;
                    let workbook = XLSX.read(data, {
                        type: "binary"
                    });
                    console.log(workbook);
                    workbook.SheetNames.forEach(sheet => {
                        let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
                        console.log(rowObject);
                        this.setEntries({objects:rowObject})
                        //document.getElementById("jsondata").innerHTML = JSON.stringify(rowObject, undefined, 4)
                    });
                }
            }
        }

    },
    computed: {
        ...mapGetters("common", ["getCurrentWord", "getCurrentWordTranslated", "getVisibility"]),
    },
    watch: {

    },
    created() {}
};
</script>

<style scoped src="@/assets/styles/def-container.css">

</style>
