<template>
<v-app class="mt-4" id="mobile-search">
    <v-navigation-drawer fill-height id="mobile" permanent color="#e4eaf0" style="max-height: 100%">
        <div class="vuebar-element">
            <v-list dense nav class="words-list pr-0">
                <v-list-item-group v-model="currentWordModel">
                    <v-list-item v-for="(word,index) in sortedArr" :key="index" @click="getCurrentWord({word:word}),logChangeOfWord(), lockItem(word.currentTranslation)" :disabled="word.lockSection" active-class="active-items" class="list-item">
                        <v-list-item-title class="item-text">{{getTranslationFromStore(word)}}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>
    </v-navigation-drawer>
</v-app>
</template>

<script>
import {
    mapActions,
    mapState,

} from 'vuex';
// eslint-disable-next-line no-unused-vars
import i18n from '@/i18n';

export default {
    name: "SearchDrawer",
    data() {
        return {}
    },
    methods: {
        ...mapActions("common", ["getTranslationFromDB", "getCurrentWord", "logChangeOfWord"]),

        getTranslationFromStore(item) {
            this.getTranslationFromDB({
                item: item
            }).then(transl => {
                item.currentTranslation = transl
            })
            return item.currentTranslation
        },

        lockItem(param) {
            for (let el of this.sortedArr) {
                if (el.currentTranslation == param) el.lockSection = true
                else el.lockSection = false
            }
        }

    },
    computed: {
        ...mapState("words", ["visible_items"]),
        ...mapState("common", ["isClicked"]),

        sortedArr: function () {
            let locale = this.$i18n.locale
            window.scrollTo(0, 0);
            document.getElementsByClassName("word-list").scrollTo = 0;

            function compare(a, b) {
                let textA, textB;
                if (locale == "fr") {
                    textA = a.inFrench.toUpperCase();
                    textB = b.inFrench.toUpperCase();
                } else {
                    textA = a.inBulgarian.toUpperCase();
                    textB = b.inBulgarian.toUpperCase();
                }
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.visible_items.sort(compare);
        },
        currentWordModel: {
            get() {
                return this.$store.state.common.currentWordModel
            },
            set(value) {
                this.$store.dispatch('common/setNewModel', value)
            }
        }
    },
};
</script>

<style scoped src="@/assets/styles/search-drawer.css">
</style>
