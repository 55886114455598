<template>
<v-app class="d-block d-md-none">
    <div class="bar">
        <v-app-bar class="pl-0 mx-3 rounded-lg" color="#002e5b">
            <div class="row">
                <div class="col-auto pl-0 mr-auto">
                    <div class="search-section" @mouseover="is_mouse_over_nav = true" @mouseleave="is_mouse_over_nav= false">
                        <v-text-field :style="search_bar_width" class="px-2 py-1 mt-6" flat id="search-field" prepend-inner-icon="mdi-magnify" v-model="key_word" clearable @click:clear="key_word = null" @input="search({key_word:key_word})" dark background-color="#19436b" dense solo :placeholder="$t('Search.title')" filled></v-text-field>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="select-container">
                        <select name="categories" @change="changeCategory()" class="classic pl-2" id="select-categories">
                            <option v-for="category in category_items" class="select-items" :key="category.order" :id="category.categoryId">
                                {{getTranslationFromStore(category)}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </v-app-bar>
    </div>
</v-app>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
    mapState,
    mapActions
} from "vuex";
export default {

    name: "CategoryTabsMobile",
    data() {
        return {
            item: null,
            key_word: null,
            is_mouse_over_nav: false,
            search_bar_width: "width:25%;"
        }
    },
    computed: {
        ...mapState("words", ["category_items"]),
    },
    methods: {
        ...mapActions("words", ["search"]),
        ...mapActions("words", ["getCategoryId"]),
        ...mapActions("common", ["getTranslationFromDB", "logChangeOfCategory"]),

        getTranslationFromStore(item) {
            this.getTranslationFromDB({
                item: item
            }).then(transl => {
                item.currentTranslation = transl
            })
            return item.currentTranslation
        },
        changeCategory() {
            var selectObj = document.getElementById("select-categories");
            let id = selectObj.options[selectObj.selectedIndex].id;
            this.getCategoryId({
                id: id
            });
            this.logChangeOfCategory()
        }
    },
    watch: {
        key_word: {
            handler() {
                return (this.key_word != null) ? this.is_mouse_over_nav = true : this.is_mouse_over_nav = false;
            },
            immediate: true
        },
        is_mouse_over_nav: {
            handler() {
                if (this.is_mouse_over_nav) {
                    if (window.innerWidth < 450) {
                        let w = (window.innerWidth / 10) + 10;
                        this.search_bar_width = "width:" + w.toString() + "%;"
                    } else this.search_bar_width = "width:100%";
                } else {
                    this.search_bar_width = "width:25%;"
                }
            },
            immediate: true
        }
    },
    created() {
        this.item = this.category_items[0]
    }
};
</script>

<style scoped src="@/assets/styles/nav-bar.css">
</style>
