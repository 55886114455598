/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n';
import {db} from '../../firebase';

Vue.use(Vuex);

const state = () =>({
    category_items: [],
    dictionary_items:[],
    current_items:[],
    visible_items:[]
})

const mutations = {
    SET_NEW_ENTRIES(state,{objects}){
        console.log("obj: ", objects)
        for(let obj of objects){
        db.collection('dictionary_entries').add(obj)
        }
    },
    GET_CATEGORIES_FROM_DB(state){
        db.collection('categories').orderBy('order').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              state.category_items.push(doc.data());
            });
          });
    },
    GET_DICTIONARY_ENTRIES_FROM_DB(state)
    {
        db.collection('dictionary_entries').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              state.dictionary_items.push(doc.data());
            });
          });
    },
    SEARCH_DICTIONARY(state,{key_word})
    {
        if(key_word == null) state.visible_items = state.current_items 
        else
        {
            key_word = key_word.toLowerCase();
            state.visible_items = []
            
            for(let word of state.current_items)
            {
                let current_word = word.currentTranslation.toLowerCase();
                let cond1 = (current_word.indexOf(key_word) != -1) && (current_word.indexOf(key_word) <= 1)
                let cond2 = (key_word.length >= 2 && current_word.includes(key_word))
                
                if(current_word.startsWith(key_word) || cond1 || cond2 )
                {
                    state.visible_items.push(word)
                }
            }
        }
    },

}

const actions={

    getItems({state, commit,dispatch}){
        commit("GET_CATEGORIES_FROM_DB")
        commit("GET_DICTIONARY_ENTRIES_FROM_DB")
        state.current_items = state.dictionary_items 
        state.visible_items = state.current_items  
    },

    getCategoryId({state,commit}, {id})
    {
        state.current_items = [];
        for(let word of state.dictionary_items)
        {
            if(id == word.categoryId) state.current_items.push(word)
            if(id == "id_0") state.current_items = state.dictionary_items
            
        }
        state.visible_items = state.current_items
    },

    search({state, commit},{key_word})
    {
        commit("SEARCH_DICTIONARY", {key_word:key_word})
    },
    setEntries({state, commit},{objects})
    {
        commit("SET_NEW_ENTRIES",{objects:objects})
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
  };