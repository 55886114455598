/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAOA8RzeJZSbxX1xerhaquZnF94WOsIZIQ",
  authDomain: "french-dictionary-9dae3.firebaseapp.com",
  projectId: "french-dictionary-9dae3",
  storageBucket: "french-dictionary-9dae3.appspot.com",
  messagingSenderId: "261179245089",
  appId: "1:261179245089:web:7e9d0e8828f553d5481960",
  measurementId: "${config.measurementId}"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export {db}
