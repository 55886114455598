<template>
<v-app id="def-container" v-show="getVisibility">
    <div class="def">
        <v-card class="def-card" height="100%">
            <v-card-title class="title-card">{{getCurrentWord}}</v-card-title>
            <v-card-text>
                <v-container class="card-text">
                    <h4 id="card-sub">{{$t('Card.translation')}}</h4> <br>
                    <h3 id="card-tran">{{getCurrentWordTranslated}}</h3>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</v-app>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
    mapGetters,
    mapState
} from 'vuex';

export default {
    name: "DefinitionContainer",
    data() {
        return {
            isClicked: false
        }
    },
    methods: {

    },
    computed: {
        ...mapGetters("common", ["getCurrentWord", "getCurrentWordTranslated", "getVisibility"]),
    },
    watch: {
        getCurrentWord: {
            handler() {
                if (this.getCurrentWord) this.isClicked = true
                else this.isClicked = false
            },
            immediate: true
        }
    },
    created() {}
};
</script>

<style scoped src="@/assets/styles/def-container.css">

</style>
