import Vue from "vue";
import Vuex from "vuex";
import words from './modules/words'
import common from './modules/common'


Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
      namespaced: true,
      words,
      common
  }
})

