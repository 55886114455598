<template>
<v-app class="d-none d-md-block" style="width:100%">
    <template>
        <div class="row mx-3 bar">
            <div class="col-md-3 col-sm-2 col-xs-1 col-4 pr-0 pt-0 " id="search-box-container">
                <div class="search-box rounded-l">
                    <v-text-field class="px-2 py-1 " dark background-color="#19436b" dense solo :placeholder="$t('Search.title')" filled clearable prepend-inner-icon="mdi-magnify" v-model="key_word" @click:clear="key_word = null" @input="search({key_word:key_word})"></v-text-field>
                </div>
            </div>
            <v-tabs class="col-md-9 col-sm-10 col-xs-11 col-8 pl-0 pt-0 subjects-container-class" id="subjects-container" v-model="tab" active-class="active" dark color="white" hide-slider background-color="#002e5b" show-arrows>
                <v-tab v-for="item in category_items" :key="item.categoryId" @click="getCategoryId({id:item.categoryId}),logChangeOfCategory()">
                    {{getTranslationFromStore(item)}}
                </v-tab>
            </v-tabs>
        </div>
    </template>
</v-app>
</template>
<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
    mapState,
    mapActions
} from "vuex";
export default {

    name: "CategoryTabs",
    data() {
        return {
            tab: null,
            key_word: null,
        }
    },
    computed: {
        ...mapState("words", ["category_items"]),
    },
    methods: {
        ...mapActions("words", ["search"]),
        ...mapActions("words", ["getCategoryId"]),
        ...mapActions("common", ["getTranslationFromDB", "logChangeOfCategory"]),

        getTranslationFromStore(item) {
            this.getTranslationFromDB({
                item: item
            }).then(transl => {
                item.currentTranslation = transl
            })
            return item.currentTranslation
        },

    },
};
</script>

<style scoped src="@/assets/styles/nav-bar.css">
</style>
